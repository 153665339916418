<ng-container *transloco="let t">
  <div class="layout-container" [ngClass]="containerClass">
    <app-sidebar></app-sidebar>
    <!-- <app-help-button #helpbtn></app-help-button> -->

    <div class="layout-content-wrapper">
      <app-topbar></app-topbar>
      <div class="layout-content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-profilesidebar></app-profilesidebar>
    <app-config></app-config>
    <div class="layout-mask" (click)="onContentClick()"></div>
  </div>
</ng-container>
