import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { StateService } from '@shared/service/state.service';

export const authenticatedGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const _stateService = inject(StateService);
  const router = inject(Router);

  try {
    if (!authService.isAuthenticated()) {
      router.navigate(['auth/login']);
      _stateService.setSelectedMenu(null!);
      return false;
    } else {
      await authService.CheckIsAdmin();
      return true;
    }
  } catch (error) {
    router.navigate(['auth/login']);
    _stateService.setSelectedMenu(null!);
    return false;
  }
};
