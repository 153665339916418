import { Routes } from '@angular/router';
import { authenticatedGuard } from '@core/guards/authenticated.guard';
import { AppLayoutComponent } from '@layout/app.layout.component';

export const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: 'app',
    component: AppLayoutComponent,
    canActivate: [authenticatedGuard],
    loadChildren: () => import('@modules/modules.routes').then((m) => m.modulesRoutes),
  },
  { path: 'sel', redirectTo: 'student', pathMatch: 'full' },
  {
    path: 'student',
    loadChildren: () => import('@modules-student/modules-student.routes').then((m) => m.modulesStudentRoutes),
  },
  {
    path: 'parents',
    loadChildren: () => import('@modules-parents/modules-parents.routes').then((m) => m.modulesParentsRoutes),
  },
  {
    path: 'skills',
    loadChildren: () => import('@modules/skills/skills.routes').then((m) => m.skillsRoutes),
  },
  {
    path: 'student/auth',
    loadChildren: () => import('@modules-student/auth-student/auth-student.routes').then((m) => m.authStudentRoutes),
  },
  {
    path: 'sel-teacher',
    loadComponent: () => import('@modules/sel-teacher/sel-teacher.component').then((m) => m.SelTeacherComponent),
  },
  {
    path: 'auth',
    data: { breadcrumb: 'Auth' },
    loadChildren: () => import('@auth/auth.routes').then((m) => m.AuthRoutes),
  },
  {
    path: 'access',
    loadComponent: () => import('@auth/pages/accessdenied/accessdenied.component').then((m) => m.AccessdeniedComponent),
  },
  {
    path: 'notfound',
    loadComponent: () => import('@core/components/notfound/notfound.component').then((m) => m.NotfoundComponent),
  },
];
